import React, { useState, useEffect } from 'react';
import { Dropdown } from "flowbite-react";


const FilterButton = React.memo(({ onClick, children, className }) => (
  <button
    onClick={onClick}
    className={`group/button inline-flex items-center leading-none hover:cursor-pointer lg:leading-none text-xs lg:text-base gap-2 lg:gap-2.5 px-2 py-1.5 lg:px-2.5 lg:py-2 rounded-md hover:text-red focus:outline-none transition-all ${className}`}
  >
    {children}
  </button>
));

const FilterComponent = ({ classes, filterSettings, startFilter, t, applyFilterCB }) => {

  const [currentFilter, setCurrentFilter] = useState({});
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);


  const toggleActiveFilter = (label, filterClass, show) => {
    //console.log('Toggling active filter:', {label, filterClass, show});
    const newFilters = activeFilters;
    if (show) {
      newFilters.push({ label, filterClass });
    } else {
      newFilters.splice(newFilters.findIndex((filter) => filter.filterClass === filterClass), 1);
    }
    setActiveFilters([...newFilters]);

  };

  useEffect(() => {
    applyFilterCB(currentFilter)
  }, [currentFilter]);

  // not implemented yet
  // useEffect(() => {
  //   setCurrentFilter(startFilter);

  // }, [startFilter]);


  const toggleFilter = (label, filterClass) => {
    //console.log('Toggling filter:', label, filterClass);
    const [type, value] = filterClass.split("|");
    const show = currentFilter[type]?.includes(value);

    const newFilter = { ...currentFilter };
    if (!newFilter[type]) {
      newFilter[type] = [];
    }
    if (newFilter[type].includes(value)) {
      newFilter[type] = newFilter[type].filter(item => item !== value);
    } else {
      newFilter[type].push(value);
    }
    setCurrentFilter(newFilter);
    toggleActiveFilter(label, filterClass, !show);
  };

  const getTypeCounts = (type) => currentFilter?.[type]?.length || 0;


  const renderMobileFilter = () => (
    <div className={`relative z-200 lg:hidden ${mobileFilterOpen ? '' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black bg-opacity-25"></div>
      <div className="fixed inset-0 flex flex-col w-full h-full max-w-xs p-5 ml-auto overflow-y-auto bg-white shadow-md pt-header-mobile scale-y-5">
        <div className="flex items-center justify-end">
          <FilterButton onClick={() => setMobileFilterOpen(false)}>
            <img className="cursor-pointer size-3 filter-black hover:filter-red" src="/imgs/icons/phosphor_light/x-light.svg" />
          </FilterButton>
        </div>
        <div className="flex flex-col divide-y divide-gray-200">
          {filterSettings?.filter?.map((filter) => (
            <div key={filter.type} className="py-5 space-y-2.5">
              <p className="inline-flex items-center gap-2 leading-none">
                <span>{t(`filter.${filter.type}.caption`)}</span>
                {/* <span className={`rounded bg-gray-50 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-black`}>{getTypeCounts(filter.type)}</span> */}
              </p>
              <ul>
                {filter.items?.map((item) => (
                  <li key={item.filterClass} className="flex items-center py-1">
                    <div className="flex gap-2.5 flex-nowrap justify-start items-center">
                      <input
                        type="checkbox"
                        checked={currentFilter[filter.type]?.includes(item.filterClass.split('|')[1]) || false}
                        onChange={() => toggleFilter(item.caption, item.filterClass)}
                        className="filter-checkbox transition-all border-gray-200 rounded size-4 bg-gray-50 text-red focus:ring-red focus:ring-2 hover:cursor-pointer"
                      />
                      <label htmlFor={`${filter.type}.${item.filterClass}-checkbox-mobile`} className="flex-grow text-sm leading-5 lg:text-base lg:leading-5 hover:text-red hover:cursor-pointer">
                        {item.caption}{1==2 && item.counts > -1 && <span className="text-gray">({item.counts})</span>}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderDesktopFilter = () => (
    <div className="hidden lg:block">
      <div className="flex flex-wrap items-center justify-end gap-2">
        {filterSettings && filterSettings?.filter?.map((filter) => {
          if (filter?.settings?.multi) {
            return (
              <div key={filter.type}>
                <Dropdown 
                dismissOnClick={false} 
                renderTrigger={() => <button
                  type="button"
                  className="group/button inline-flex items-center leading-none hover:cursor-pointer lg:leading-none text-xs lg:text-base gap-1 lg:gap-2 px-1 py-1.5 lg:py-2 rounded-md hover:text-red focus:outline-none transition-all"
                  
                  >
                  <span className="h-3 transition-all lg:h-4 button-caption">{t(`filter.${filter.type}.caption`)}</span>
                  <span className="button-icon">
                    <img className="size-3 lg:size-4 filter-black group-hover/button:filter-red" src="/imgs/icons/phosphor_light/caret-down-light.svg" />
                  </span>
                </button>}
                >
                  {filter.items?.map((item) => (
                    <Dropdown.Item onClick={() => toggleFilter(item?.caption, item?.filterClass)} key={item?.filterClass}>{item.caption}{1==2 && <span className="text-gray"> ({item?.counts})</span>}</Dropdown.Item>
                  ))}
                </Dropdown>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
  //console.log("filter->render", { filterSettings, curFilter: JSON.stringify(currentFilter), activeFilters: JSON.stringify(activeFilters) });
  return (
    <div className={classes}>
      {renderMobileFilter()}
      <div className="space-y-2.5">
        <div className="flex items-baseline justify-between">
          <div className="flex flex-wrap items-center justify-start gap-2">
            {filterSettings && filterSettings?.sorting && (
              <div>
                <FilterButton data-dropdown-toggle="dropdown-sort">
                  <span className="h-3 transition-all lg:h-4 button-caption">{t("filter.sort.caption")}</span>
                  <img className="size-3 lg:size-4 filter-black group-hover/button:filter-red" src="/imgs/icons/phosphor_light/caret-down-light.svg" />
                </FilterButton>
                {/* Implement dropdown menu for sorting */}
              </div>
            )}
          </div>
          <div className="lg:hidden">
            <FilterButton onClick={() => setMobileFilterOpen(true)}>
              <span className="h-3 transition-all lg:h-4 button-caption">{t("filter.filter.caption")}</span>
              <img className="size-3 lg:size-4 filter-black group-hover/button:filter-red" src="/imgs/icons/phosphor_light/funnel-light.svg" />
            </FilterButton>
          </div>
          {renderDesktopFilter()}
        </div>
        <div className="active-filters flex flex-wrap gap-1">
          {activeFilters.map(({ label, filterClass }) => {
            return (
              <span key={filterClass} className="inline-flex items-center px-2 py-1 text-xs text-gray-800 bg-white rounded-md gap-x-1">
                <span className="tag-caption">{label}</span>
                <span className="tag-icon" onClick={() => toggleFilter(label, filterClass)}>
                  <img className="cursor-pointer size-3 filter-black hover:filter-red" src="/imgs/icons/phosphor_light/x-light.svg" />
                </span>
              </span>
            )
          }
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(FilterComponent);